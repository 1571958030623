@import 'tools/mixins/_apply-map';
@import 'tools/mixins/_font';
@import 'tools/mixins/_regular-text';
@import 'tools/mixins/_medium-text';
@import 'tools/mixins/_bold-text';
@import 'tools/mixins/_display-text';

// Titles
@mixin page-title {
    @include apply-map($f-title);
    @include font(80px, 96px);

    @include breakpoint(tablet) {
        @include font(60px, 72px);
    }

    @include breakpoint(mobile) {
        @include font(40px, 48px);
    }
}

@mixin large-title {
    @include apply-map($f-title);
    @include font(60px, 66px);

    @include breakpoint(tablet) {
        @include font(44px, 48px);
    }

    @include breakpoint(mobile) {
        @include font(32px, 36px);
    }
}

@mixin medium-title {
    @include font(40px, 52px);

    @include breakpoint(tablet) {
        @include font(36px, 46px);
    }

    @include breakpoint(mobile) {
        @include font(28px, 36px);
    }
}

@mixin h1 {
    @include apply-map($f-title);
    @include font(48px, 50px);

    @include breakpoint(phablet) {
        @include font(30px, 38px);
    }
}

@mixin h2 {
    @include font(32px, 38px);
}

@mixin responsive-h2 {
    @include font(32px, 38px);

    @include breakpoint(tablet) {
        @include font(24px, 30px);
    }
}

@mixin h3 {
    @include font(24px, 28px);
}

@mixin h4 {
    @include font(18px, 24px);
}

@mixin h5 {
    @include font(16px, 22px);
}

@mixin h6 {
    @include font(12px, 16px);
}

@mixin body {
    @include font(14px, 22px);
}

@mixin responsive-body {
    @include font(14px, 22px);

    @include breakpoint(tablet) {
        @include font(12px, 20px);
    }
}

@mixin compact-body {
    @include font(14px, 18px);
}

@mixin large-subtitle {
    @include font(32px, 42px);

    @include breakpoint(tablet) {
        @include font(24px, 32px);
    }
}

@mixin medium-subtitle {
    @include font(24px, 32px);

    @include breakpoint(tablet) {
        @include font(20px, 26px);
    }
}

@mixin small-subtitle {
    @include font(20px, 26px);

    @include breakpoint(tablet) {
        @include font(18px, 24px);
    }
}

@mixin smallcaps {
    @include apply-map($f-smallcaps);
    @include rem(12px);
}

@mixin smallprint {
    @include apply-map($f-smallprint);
    @include rem(12px);
}

@mixin page-intro-title {
    @include apply-map($f-title);
    @include font(60px, 70px);

    @include breakpoint(phablet) {
        @include font(42px, 48px);
    }
}

@mixin page-intro-subtitle {
    @include font(18px, 24px);

    @include breakpoint(phablet) {
        @include font(14px, 20px);
    }
}


@mixin tab-title {
    @include font(32px, 48px);

    position: relative;
    padding: 10px 0;

    @include breakpoint(tablet) {
        @include font(18px, 28px);
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background: $c-black;
        transition: width .3s ease;
    }

    &:hover,
    &:focus,
    &[aria-selected="true"] {
        &:after {
            width: 100%;
        }
    }
}

@mixin notice {
    @include font(14px, 18px);

    @include breakpoint(phablet) {
        @include font(12px, 18px);
        @include boldText;
    }
}
