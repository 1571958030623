@import 'tools/mixins/_margin';

.products-list {}

.products-list--v2 .band__title,
.products-list__tab-title {
    @include font(32px, 48px);

    position: relative;
    padding: 10px 0;

    @include breakpoint(phablet) {
        @include font(18px, 32px);

        padding: 4px 0;
    }
}

.products-list--v2.carousel--no-tabs .band__title {
    @include breakpoint(phablet) {
        @include font(24px, 30px);
    }
}

.products-list__tab-title {
    @include tab-title;
}

.products-list__tab-title {
    background-color: transparent;
}

.products-list--v2 {
    .band__title {
        padding: 10px 0;
    }

    &.carousel--no-tabs {
        .band__title {
            padding: 0;
        }
    }
}

    .products-list__header {
        position: relative;
        margin: 0 $site-gutter;

        .products-list--v2 & {
            margin-bottom: 40px;
        }

        .carousel--no-tabs & {
            margin-bottom: 30px;
        }

        @include breakpoint(phablet) {
            margin: 0 $site-gutter--mobile;

            .products-list--v2 & {
                margin-bottom: 16px;
            }
        }
    }

    .products-list__tabs {
        position: relative;
        display: flex;
        align-items: center;
        gap: 48px;
        padding-left: $site-gutter;
        padding-right: $site-gutter;
        margin-bottom: 40px;
        padding-top: 1px;

        @include breakpoint(phablet) {
            gap: 24px;
            margin-bottom: 24px;
            padding-left: $site-gutter--mobile;
            padding-right: $site-gutter--mobile;
        }

        @include breakpoint(tiny) {
            gap: 10px;
        }
    }

            .products-list__tab-title-label {
                pointer-events: none;
            }

        .products-list__controls {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @include breakpoint(phablet) {
                display: none;
            }
        }

            .products-list__left,
            .products-list__right {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                background: transparent;

                &[disabled] {
                    opacity: 0.3;
                    pointer-events: none;
                }

                svg {
                    width: 6px;
                    height: 10px;
                }
            }

    .products-list__container {
        @include margin(bottom, 20px);

        display: flex;
        overflow: auto;
        padding-top: 9px;
        padding-bottom: 20px;
        padding-left: $site-gutter;

        .products-list--v2 & {
            padding-top: 0;
            padding-bottom: 0;
        }

        @include breakpoint(phablet) {
            @include margin(bottom, 10px);

            padding-left: $site-gutter--mobile;
        }
    }

    .products-list__footer {
        margin: 0 $site-gutter;

        @include breakpoint(phablet) {
            margin: 0 $site-gutter--mobile;
        }
    }
