:root {
  --c-border: #000000;
  --c-panel-background: #FFFFFF;
  --c-disabled: #EEEEEE;
  --c-focus: #000000;
  --c-field-disabled: var(--c-disabled);
  --c-field-focus: var(--c-focus);
  --c-input-background: var(--c-panel-background);
  --c-input-border: var(--c-border);
}

.products-list--v2 .band__title,
.products-list__tab-title {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.5;
  position: relative;
  padding: 10px 0;
}
@media screen and (max-width: 42.5em) {
  .products-list--v2 .band__title,
  .products-list__tab-title {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.7777777778;
    padding: 4px 0;
  }
}

@media screen and (max-width: 42.5em) {
  .products-list--v2.carousel--no-tabs .band__title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

.products-list__tab-title {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.5;
  position: relative;
  padding: 10px 0;
}
@media screen and (max-width: 62.4375em) {
  .products-list__tab-title {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
.products-list__tab-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #000000;
  transition: width 0.3s ease;
}
.products-list__tab-title:hover:after, .products-list__tab-title:focus:after, .products-list__tab-title[aria-selected=true]:after {
  width: 100%;
}

.products-list__tab-title {
  background-color: transparent;
}

.products-list--v2 .band__title {
  padding: 10px 0;
}
.products-list--v2.carousel--no-tabs .band__title {
  padding: 0;
}

.products-list__header {
  position: relative;
  margin: 0 24px;
}
.products-list--v2 .products-list__header {
  margin-bottom: 40px;
}
.carousel--no-tabs .products-list__header {
  margin-bottom: 30px;
}
@media screen and (max-width: 42.5em) {
  .products-list__header {
    margin: 0 16px;
  }
  .products-list--v2 .products-list__header {
    margin-bottom: 16px;
  }
}

.products-list__tabs {
  position: relative;
  display: flex;
  align-items: center;
  gap: 48px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 40px;
  padding-top: 1px;
}
@media screen and (max-width: 42.5em) {
  .products-list__tabs {
    gap: 24px;
    margin-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media screen and (max-width: 23.75em) {
  .products-list__tabs {
    gap: 10px;
  }
}

.products-list__tab-title-label {
  pointer-events: none;
}

.products-list__controls {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 42.5em) {
  .products-list__controls {
    display: none;
  }
}

.products-list__left,
.products-list__right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: transparent;
}
.products-list__left[disabled],
.products-list__right[disabled] {
  opacity: 0.3;
  pointer-events: none;
}
.products-list__left svg,
.products-list__right svg {
  width: 6px;
  height: 10px;
}

.products-list__container {
  margin-bottom: 20px;
  display: flex;
  overflow: auto;
  padding-top: 9px;
  padding-bottom: 20px;
  padding-left: 24px;
}
.products-list__container:last-child {
  margin-bottom: 0;
}
.products-list--v2 .products-list__container {
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (max-width: 42.5em) {
  .products-list__container {
    margin-bottom: 10px;
    padding-left: 16px;
  }
  .products-list__container:last-child {
    margin-bottom: 0;
  }
}

.products-list__footer {
  margin: 0 24px;
}
@media screen and (max-width: 42.5em) {
  .products-list__footer {
    margin: 0 16px;
  }
}